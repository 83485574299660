import React from "react";
import "./header.css";
import SearchIcon from "../../assets/Vector.svg";
import NotificationIcon from "../../assets/carbon_notification-new.svg";
import ProfileImg from "../../assets/Rectangle.svg";
import Logo from "../../assets/arcticons_reactiontraining.svg";

const Header = () => {
	return (
		<div className="header">
			<p>
				Good Morning <span>Alan</span>
			</p>
			<div className="mobile_header">
				<img src={Logo} />
				<h1>Dashyy</h1>
			</div>
			<div className="header_search">
				<input placeholder="search" />
				<img src={SearchIcon} />
				<img src={NotificationIcon} />
			</div>
			<div className="header_logout">
				<button>Logout</button>
				<img src={ProfileImg} />
			</div>
		</div>
	);
};

export default Header;

import React from "react";
import "./salesinfo.css";

const SalesInfo = () => {
	return (
		<div className="container container_top sales_container">
			<div>
				<h2>Total Sales</h2>
				<h1>
					103 <span>-6%</span>
				</h1>
			</div>
			<div>
				<h2>Total Orders</h2>
				<h1>
					103 <span className="profit_green">-77%</span>
				</h1>
			</div>
			<div>
				<h2>Cancelled</h2>
				<h1>
					88 <span>-6%</span>
				</h1>
			</div>
		</div>
	);
};

export default SalesInfo;

import React from "react";
import "./users.css";

const Users = () => {
	return (
		<div className="container container_top user_container">
			<h2>New Users</h2>
			<h1>55</h1>
		</div>
	);
};

export default Users;

import "./App.css";
import Header from "./Components/Header/Header";
import Users from "./Components/NewUsers/Users";
import ProductsInfo from "./Components/ProductsInfo/ProductsInfo";
import SalesInfo from "./Components/SaledInfo/SalesInfo";
import ShippingInfo from "./Components/ShippingInfo/ShippingInfo";
import SideBar from "./Components/SideBar/SideBar";
import TotalOrders from "./Components/TotalOrders/TotalOrders";
import TotalSales from "./Components/TotalSales/TotalSales";
import Icon1 from "../src/assets/home.svg";
import Icon2 from "../src/assets/circle.svg";
import Icon3 from "../src/assets/chat.svg";
import Icon4 from "../src/assets/plus.svg";
import Icon5 from "../src/assets/delete.svg";

function App() {
	return (
		<div className="App">
			{/* <div className="header h-[10vh] bg-gray-300 mb-2">
				<Header />
			</div>
			<div className=" h-[90vh] grid grid-cols-12 grid-rows-6 bg-gray-300 gap-2">
				<div className="col-span-1 bg-gray-50 row-span-6">
					<SideBar />
				</div>
				<div className="col-span-3 bg-gray-50 row-span-2">
					<TotalOrders />
				</div>
				<div className="col-span-5 bg-gray-50 row-span-2">
					<SalesInfo />
				</div>
				<div className="col-span-3 bg-gray-50 row-span-2">
					<Users />
				</div>
				<div className="col-span-3 bg-gray-50 row-span-1">1</div>
				<div className="col-span-5 bg-gray-50 row-span-1">2</div>
				<div className="col-span-3 bg-gray-50 row-span-1">3</div>
				<div className="col-span-3 bg-gray-50 row-span-3">
					<ProductsInfo />
				</div>
				<div className="col-span-5 bg-gray-50 row-span-3">
					<ShippingInfo />
				</div>
				<div className="col-span-3 bg-gray-50 row-span-3">
					<TotalSales />
				</div>
			</div> */}
			<div className="flex">
				<div className="left-side">
					<SideBar />
				</div>
				<div className="right_side">
					<div>
						<Header />
						<p className="mobile_text">
							Good Morning <span>Alan</span>
						</p>
					</div>
					<div className="main">
						<TotalOrders />
						<SalesInfo />
						<Users />
						<ProductsInfo />
						<ShippingInfo />
						<TotalSales />
					</div>
				</div>
			</div>
			<div className="mobile_navbar">
				<ul>
					<img src={Icon1} />
					<img src={Icon2} />
					<img src={Icon3} />
					<img src={Icon4} />
					<img src={Icon5} />
				</ul>
			</div>
		</div>
	);
}

export default App;

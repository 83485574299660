import React from "react";
import "./totalsales.css";
import Team1 from "../../assets/team1.png";

const TotalSales = () => {
	return (
		<div className="container total_sales_container">
			<h1>
				Team:<span>Online</span>
			</h1>
			<p>Work together at same time.</p>
			<div className="total_sales_half_circle">
				<img src={Team1} className="first" />
				<img src={Team1} />
				<img src={Team1} />
				<img src={Team1} />
				<img src={Team1} />
			</div>
			<CreditCard />
		</div>
	);
};
const CreditCard = () => {
	return (
		<div className="card_container">
			<h1>Total Credit</h1>
			<h1>120,000</h1>
			<button>View Details</button>
		</div>
	);
};

export default TotalSales;

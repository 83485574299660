import React from "react";
import "./sideBar.css";
import Logo from "../../assets/arcticons_reactiontraining.svg";
import Icon1 from "../../assets/home.svg";
import Icon2 from "../../assets/circle.svg";
import Icon3 from "../../assets/chat.svg";
import Icon4 from "../../assets/plus.svg";
import Icon5 from "../../assets/delete.svg";

const SideBar = () => {
	return (
		<div className="sidebar">
			<div className="sidebar_logo">
				<img src={Logo} />
				<h1>Dashyy</h1>
			</div>
			<div className="sidebar_nav-links">
				<img src={Icon1} />
				<img src={Icon2} />
				<img src={Icon3} />
				<img src={Icon4} />
				<img src={Icon5} />
			</div>
		</div>
	);
};

export default SideBar;

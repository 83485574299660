import React from "react";
import Cloth1 from "../../assets/cloth1.svg";
import "./shipping.css";

const ShippingInfo = () => {
	return (
		<div className="container shipping_container">
			<h1>Shipping</h1>
			<div className="shipping_seperator">
				<div>
					<h3>iPad Pro 2017 Model</h3>
					<p>5 left</p>
				</div>
				<img src={Cloth1} />
			</div>
			<div className="shipping_seperator">
				<div>
					<h3>iPad Pro 2017 Model</h3>
					<p>5 left</p>
				</div>
				<img src={Cloth1} />
			</div>
			<div className="shipping_seperator">
				<div>
					<h3>iPad Pro 2017 Model</h3>
					<p>5 left</p>
				</div>
				<img src={Cloth1} />
			</div>
			<div className="shipping_seperator">
				<div>
					<h3>iPad Pro 2017 Model</h3>
					<p>5 left</p>
				</div>
				<img src={Cloth1} />
			</div>
			<div className="shipping_seperator">
				<div>
					<h3>iPad Pro 2017 Model</h3>
					<p>5 left</p>
				</div>
				<img src={Cloth1} />
			</div>
		</div>
	);
};

export default ShippingInfo;

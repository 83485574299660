import React from "react";
import "./product.css";

const ProductsInfo = () => {
	return (
		<div className="container product_container">
			<h1>
				Products <span>10</span>
			</h1>
			<div>
				<h3>iPad Pro 2017 Model</h3>
				<p>5 left</p>
			</div>
			<div>
				<h3>iPad Pro 2017 Model</h3>
				<p>5 left</p>
			</div>
			<div>
				<h3>iPad Pro 2017 Model</h3>
				<p>5 left</p>
			</div>
			<div>
				<h3>iPad Pro 2017 Model</h3>
				<p>5 left</p>
			</div>
			<div>
				<h3>iPad Pro 2017 Model</h3>
				<p>5 left</p>
			</div>
		</div>
	);
};

export default ProductsInfo;

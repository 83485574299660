import React from "react";
import "./totalOrder.css";
import ProfileImg from "../../assets/Rectangle.svg";

const TotalOrders = () => {
	return (
		<div className="container container_top total_order_container">
			<h2>Active orders</h2>
			<div className="orders_info">
				<img src={ProfileImg} />
				<h2>
					Order<span>#9978</span>
				</h2>
			</div>
		</div>
	);
};

export default TotalOrders;
